<template>
	<div>
		<div class="container darkBack">
			<div class="font20 whiteFont weight">线上商城数据</div>
			<!-- 数据区域 -->
			<div class="mallData">
				<div class="mallDataItem borderBack">
					<p class="font14 lightBlueFont">{{sales.title}}</p>
					<p class="font22 blueFont" style="margin-top:0.05rem;">
						<countTo :startVal='0' :endVal='sales.num' :decimals="2" :duration='3000'></countTo>
					</p>
				</div>
				<div class="mallDataItem borderBack">
					<p class="font14 lightBlueFont">{{merchants.title}}</p>
					<p class="font22 blueFont" style="margin-top:0.05rem;">
						<countTo :startVal='0' :endVal='merchants.num' :duration='3000'></countTo>
					</p>
				</div>
			</div>
			<!-- 图表 -->
			<div style="margin-top: 0.5rem;">
				<div class="blueFont">● 用户喜好商品类别</div>
				<div id="userLike"></div>
			</div>
		</div>
	</div>
</template>

<script>
	// 引入数字滚动组件
import countTo from 'vue-count-to';
import bus from "../../../assets/js/bus"
  export default {
    components: { countTo },
		data() {
			return{
				sales:{title:"销售额",num:846224234.87},
				merchants: {title:"入驻商家数",num:2078},

				like: {
					yAxis:['运动配件', '智能装备', '运动服饰', '运动跑鞋', '体育服务','场馆预定','赛事活动'],
					data:[5,32,10,54,32,15,54],
				}
			}
		},
		methods:{
			// 绘制用户喜好商品表
			drawUserLike(number){
				let that = this;
				// let myChart = this.$echarts.init(document.getElementById("userLike"));
				
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("userLike"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("userLike"));
				}

				let data = number.data
				// for (let i = 0; i < 7; ++i) {
				// 	data.push(Math.round(Math.random() * 200));
				// }
				
				let option = {
					xAxis: {
						// 显示网格线
						splitLine:{
							show: false,
						},
						// 不显示刻度值
						axisLabel:{show:false}
					},
					grid: {         // 间距
						left: '6%',  
						right: '10%', 
						top:'8%', 
						bottom: '6%',  
						containLabel: true,
					},
					yAxis: {
						type: 'category',
						data: number.yAxis,
						// animationDuration: 300,
						// animationDurationUpdate: 300,
						//y轴显示及颜色
						axisLine:{
							show:false,
							lineStyle:{
								color:'#F66901',
							}
						},
						// 刻度不显示
						axisTick:{show:false},
						// y轴字体样式
						axisLabel:{
							color:'#7EA7E2',
							fontSize:'0.14rem'
						},
					},
					series: [{
						// 实时排序
						// realtimeSort: true,
						type: 'bar',
						data: number.data,
						// 柱子宽度
						barWidth:'40%',
						// 柱子间距
						barCategoryGap:10,
						// 数据显示
						label: {
							show: true,
							position: 'right',
							color:"#F1F1F1",
							valueAnimation: true,
                            fontSize:'0.14rem',
						},
						itemStyle: {
							normal: {
								color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
									offset: 0,
									color: "#F66901" // 0% 处的颜色
								}, {
									offset: 1,
									color: "#EFDC29" // 100% 处的颜色
								}], false)
							}
						},
					}],
					// animationDuration: 0,
					// animationDurationUpdate: 3000,
					// animationEasing: 'linear',
					// animationEasingUpdate: 'linear'
				};
				
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				  $("#userLike").css({width:"5.0rem",height:"3.2rem"})
				});
				// function run () {
				// 	var data = option.series[0].data;
				// 	for (var i = 0; i < data.length; ++i) {
				// 		if (Math.random() > 0.9) {
				// 			data[i] += Math.round(Math.random() * 2000);
				// 			option.series[0].itemStyle.normal.color = that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
				// 															offset: 0,
				// 															color: "#F66901" // 0% 处的颜色
				// 														}, {
				// 															offset: 1,
				// 															color: "#EFDC29" // 100% 处的颜色
				// 														}], false)
				// 		}
				// 		else {
				// 			data[i] += Math.round(Math.random() * 200);
				// 			option.series[0].itemStyle.normal.color = that.$echarts.graphic.LinearGradient(0, 0, 1, 0, [{
				// 															offset: 0,
				// 															color: "#F66901" // 0% 处的颜色
				// 														}, {
				// 															offset: 1,
				// 															color: "#EFDC29" // 100% 处的颜色
				// 														}], false)
				// 		}
				// 	}
				// 	myChart.setOption(option);
				// }
				
				// setTimeout(function() {
				// 	run();
				// }, 0);
				// setInterval(function () {
				// 	run();
				// }, 3000);
			}
		},
		mounted() {
			let that = this;
			that.drawUserLike(that.like);
			bus.$on('goods',function(res){
				// console.log(res)
				that.sales.num = res.sales_count;
				that.merchants.num = res.shop_num;
				that.like.yAxis = [];
				that.like.data = [];
				res.shop_category.forEach((item,index)=>{
					that.like.yAxis.push(item.name);
					that.like.data.push(item.goods_num_text);
				})
				that.drawUserLike(that.like);
				// console.log(that.like)
			})
		},
		destroyed () {
			bus.$off('goods');
		}
	}
</script>

<style scoped>
	.weight{font-weight: bold;}
	.container{
		width: 5.04rem;
		height: 5.59rem;
		position: relative;
		padding: 0.2rem;
	}
	.mallData{
		display: flex;
		justify-content: space-around;
		margin-top: 0.3rem;
	}
	.mallDataItem{
		width: 2rem;
		height: 0.73rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	#userLike{
		width: 5.00rem;
		height: 3.20rem;
		margin-left:-0.2rem;
	}
</style>